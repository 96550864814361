import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Header from "../component/Header";
// import UploadJson from "../component/UploadJson";
import UploadPdf from "../component/UploadPdf";
import UploadOmniXls from "../component/UploadOmniXls";
import UploadFiles from "../component/UploadFiles";
import ButtonCom from "../component/ButtonCom";
import Footer from "../component/Footer";

const Home = () => {
  // State to keep track of the current div index
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fileUploadStatus, setFileUploadStatus] = useState(false);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [inputTargetFiles, setInputTargetFiles] = useState([]);
  const [fileTitle, setFileTitle] = useState("JSON");
  const [progress, setProgress] = useState({ started: false, pc: 0 });
  const [uploadMsg, setUploadMsg] = useState();
  const [guid, setGuid] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');

  const handleAllFileUpload = (files) => {
    setUploadedFile((uploadedFile) => [...uploadedFile, files]);
  };

  const handleFileUpload = (files) => {
    setInputTargetFiles((uploadedFile) => [...uploadedFile, files]);
  };
  // Array of div contents
  const components = [
    // {
    //   component: UploadJson,
    //   props: {
    //     handleFileChange: handleAllFileUpload,
    //     fileUploadStatus: setFileUploadStatus,
    //     setFileTitle: setFileTitle,
    //   },
    // },
    {
      component: UploadPdf,
      props: {
        handleFileChange: handleAllFileUpload,
        fileUploadStatus: setFileUploadStatus,
        setFileTitle: setFileTitle,
        setInputTargetFiles: handleFileUpload,
        counter: currentIndex,
        isFileAvailable: uploadedFile,
        isTargetAvilable: inputTargetFiles,
      },
    },
    {
      component: UploadOmniXls,
      props: {
        handleFileChange: handleAllFileUpload,
        fileUploadStatus: setFileUploadStatus,
        setInputTargetFiles: handleFileUpload,
        setFileTitle: setFileTitle,
        pageTitle: "Omni Data Excel",
        firstTabValue: "Audience Counts",
        counter: currentIndex,
        isFileAvailable: uploadedFile,
        isTargetAvilable: inputTargetFiles,
      },
    },
    {
      component: UploadOmniXls,
      props: {
        handleFileChange: handleAllFileUpload,
        fileUploadStatus: setFileUploadStatus,
        setInputTargetFiles: handleFileUpload,
        setFileTitle: setFileTitle,
        pageTitle: "Rc Data Excel",
        firstTabValue: "Passion Summary",
        counter: currentIndex,
        isFileAvailable: uploadedFile,
        isTargetAvilable: inputTargetFiles,
      },
    },
    {
      component: UploadFiles,
      props: {
        filesList: uploadedFile,
      },
    },
  ];

  const comWrap = components.map((item, index) => {
    const Component = item.component;
    return (
      <div
        key={index}
        data-key={index}
        className={`comWrapClass ${progress.started ? "hidden" : ""}`}
      >
        {!progress.started && <Component {...item.props} />}
      </div>
    );
  });
  // Function to handle the next button click
  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      checkIndexAndExecute((prevIndex + 1) % comWrap.length);
      return (prevIndex + 1) % comWrap.length;
    });
  };
  

  const checkIndexAndExecute = useCallback((index) => {
    if (
      index >= 0 &&
      index < uploadedFile.length &&
      uploadedFile[index] !== undefined
    ) {
      setFileUploadStatus(true);
    } else {
      setFileUploadStatus(false);
    }
  }, [uploadedFile, setFileUploadStatus]);

  // Function to handle the previous button click
  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + comWrap.length) % comWrap.length
    );
  };
// // Function to generate a GUID
// const generateGUID = () => {
//   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
//     const r = (Math.random() * 16) | 0,
//       v = c === 'x' ? r : (r & 0x3) | 0x8;
//     return v.toString(16);
//   });
// };
// Function to extract GUID from URL
const getGUIDFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('guid');
};
  //for upload
  const handleUpload = async () => {
    const formData = new FormData();
    for (let i = 0; i < inputTargetFiles.length; i++) {
      formData.append("files", inputTargetFiles[i]);
    }

    try {
      setUploadMsg("Uploading and Processing...");
      setProgress((prevState) => {
        return { ...prevState, started: true };
      });
      // const guid = generateGUID();
      const guid = getGUIDFromURL(); // Get the GUID from URL
      if (!guid) {
        throw new Error("GUID not found in URL");
      }
      const postUrl = `https://testapi.wearetma.biz/api/briefbotapi/fileupload/${guid}`;
      const response = await axios.post(postUrl, formData, {
          onUploadProgress: (progressEvent) => {
            setProgress((prevState) => {
              return { ...prevState, pc: progressEvent.progress * 100 };
            });
          },
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setUploadMsg(response.data.message);
    console.log(response.data);
    } catch (error) {
      setUploadMsg("Error uploading files:", error);
    }
  };

  useEffect(() => {
    const guidFromURL = getGUIDFromURL();
    if (!guidFromURL) {
      setErrorMsg("This is not a valid URL");
    } else {
      setGuid(guidFromURL);
    }
  }, []);

  useEffect(() => {
    if (guid) {
      const wrapEle = document
        .querySelector(".comWrapClass")
        .getAttribute("data-key");
      checkIndexAndExecute(+wrapEle);
    }
  }, [guid, uploadedFile, inputTargetFiles, checkIndexAndExecute]);

  return (
    <>
      <Header />
      <main className="container">
      {errorMsg ? (
          <div className="heading-wrap error"><h1>{errorMsg}</h1></div>
        ) : (
        <div className="uploadWrap">
          <div className="heading-wrap">
            {currentIndex > comWrap.length - 2 ? (
              <h1>uploaded documents</h1>
            ) : (
              <h1>Upload {fileTitle} File</h1>
            )}
            <ul className="pagerNav" style={{'display': 'inline-block'}}>
                {components.map((_,index) => (
                  <li key={index} className={`step ${currentIndex <= index ? '' : 'finish'}`}></li>
                ))}
                 {/* finish */}
            </ul>
          </div>

          {progress.started && (
            <div className="progressWrap">
              <progress max="100" value={progress.pc}></progress>
              <p>{uploadMsg}</p>
            </div>
          )}

          {comWrap[currentIndex]}

          <div
            className={`button-wrap ${progress.started ? 'hidden' : ''}`}
            style={{
              justifyContent: progress.started ? "center" : "space-between",
            }}
          >
            {!progress.started && (
              <>
                {currentIndex === 0 ? (
                  <span></span>
                ) : (
                  <ButtonCom
                    handleClassName="button prevButton"
                    handleOnClick={handlePrevious}
                  >
                    Previous
                  </ButtonCom>
                )}

                {currentIndex > comWrap.length - 2 ? (
                  <ButtonCom
                    handleClassName="button"
                    handleOnClick={handleUpload}
                  >
                    Submit
                  </ButtonCom>
                ) : (
                  <ButtonCom
                    handleClassName={`button ${
                      !fileUploadStatus ? "disabled" : ""
                    }`}
                    handleOnClick={fileUploadStatus ? handleNext : null}
                  >
                    Next
                  </ButtonCom>
                )}
              </>
            )}
          </div>
        </div>
         )}
      </main>
      <Footer />
    </>
  );
};

export default Home;
